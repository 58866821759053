import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"

import DashboardComponent from ".."
import Input from "../../Reusable/Input"
import TextArea from "../../Reusable/Input/textarea"
import Button from "../../Reusable/Button"
import InputImage from "../../Reusable/Input/imagefile"

import * as sessionSelectors from "../../../redux/slices/session/selectors"
import * as sessionThunks from "../../../redux/slices/session/thunks"

import { Styles } from "./style"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {
  editProfile: sessionThunks.editProfile,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

function UbahProfilComponent(props) {
  const { user, editProfile } = props

  const [tempUser, setTempUser] = useState({})
  const [loading, setLoading] = useState(false)
  const form = useRef(null)

  const controlledUser = attr => e => {
    const newUser = { ...tempUser }
    newUser[attr] = e.target.value
    setTempUser(newUser)
  }

  useEffect(() => {
    if (user.email) {
      sessionThunks.checkMyself()
      setTempUser(user)
    }
  }, [user])

  const submit = async e => {
    e.preventDefault()
    setLoading(true)

    const formData = new FormData(form.current)

    if (!formData.get("profile_picture").name)
      formData.delete("profile_picture")

    editProfile(formData, "multipart/form-data").then(() => {
      setLoading(false)
    })
  }

  return (
    <DashboardComponent chosen={0}>
      <Styles className="layout">
        <form ref={form} onSubmit={submit}>
          <h3
            style={{
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            Ubah Profil
          </h3>
          <div className="profil-photo-text">
            <div>
              <InputImage
                name="profile_picture"
                picture={tempUser.profile_picture}
              />
            </div>
            <div>
              <div className="input-wrapper">
                <Input
                  className="input-name"
                  type="text"
                  name="display_name"
                  placeholder="Your name here"
                  label="Name"
                  value={tempUser.display_name || ""}
                  onChange={controlledUser("display_name")}
                />
              </div>
              <div className="input-wrapper">
                <Input
                  className="input-email"
                  type="text"
                  name="email"
                  placeholder="Your email here"
                  label="Email"
                  value={user.email}
                  readOnly
                />
              </div>
              <div className="input-wrapper">
                <Input
                  className="input-phone"
                  type="text"
                  name="phone"
                  placeholder="Your phone number"
                  label="Phone Number"
                  value={tempUser.phone || ""}
                  onChange={controlledUser("phone")}
                />
              </div>
              <div className="input-wrapper">
                <Input
                  className="input-birthdate"
                  type="date"
                  name="dob"
                  label="Date of Birth"
                  value={tempUser.dob || ""}
                  onChange={controlledUser("dob")}
                />
              </div>
              <div className="input-wrapper">
                <TextArea
                  className="input-bio"
                  name="bio"
                  placeholder="Write your bio here"
                  label="Bio"
                  value={tempUser.bio || ""}
                  onChange={controlledUser("bio")}
                  rows={5}
                />
              </div>
            </div>
          </div>
          <p>
            <Button disabled={loading} type="submit">
              {loading ? "Loading..." : "Submit"}
            </Button>
          </p>
        </form>
      </Styles>
    </DashboardComponent>
  )
}

export default withConnect(UbahProfilComponent)
