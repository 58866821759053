import * as React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import UbahProfilComponent from "../../components/Dashboard/UbahProfilComponent"

const DashboardProfilPage = () => {
  return (
    <Layout>
      <SEO title="Ubah Profil" />
      <UbahProfilComponent />
    </Layout>
  )
}

export default DashboardProfilPage
