import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import ImageUploader from "react-images-upload"

import cameraLogo from "../../../images/profile_page/icon-akun.png"

import { isBrowser } from "../../../services/utils"

const Styles = styled.div`
  display: inline-block;
  width: 100%;

  label {
    color: #51b848;
  }

  .fileContainer {
    padding: 0;
    margin: 0;
    width: 270px;
    height: 270px;
    background: transparent;
    box-shadow: none;
    border: 1px dashed #a0a4a8;
  }

  .chooseFileButton {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin: 0 !important;
    background: white;
    color: black;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .chooseFileButton img {
    width: 100%;
  }

  .has-picture img {
    opacity: 0;
    transition: 0.2s opacity;
  }

  .chooseFileButton:hover {
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: white;
    opacity: 0.8;

    .has-picture img {
      opacity: 1;
    }
  }

  .uploadIcon,
  .fileContainer p {
    display: none;
  }
`

export default function ImageFile(props) {
  const myRef = useRef(null)

  const { label, id, picture, ...otherProps } = props
  const inputId = id !== null ? id : props.name

  const [upPicture, setUpPicture] = useState("")

  const showPicture = () => {
    if (isBrowser()) {
      if (upPicture) {
        const reader = new FileReader()
        reader.onload = e => {
          let elem = myRef.current.getElementsByClassName("chooseFileButton")[0]
          if (!elem && myRef.current.classList.contains("chooseFileButton")) {
            elem = myRef.current
          }
          elem.style.backgroundImage = `url(${e.target.result})`
        }
        reader.readAsDataURL(upPicture)
      }
    }
  }

  const updateChooseFileLogo = () => {
    if (isBrowser()) {
      let elem = myRef.current.getElementsByClassName("chooseFileButton")[0]
      if (!elem && myRef.current.classList.contains("chooseFileButton")) {
        elem = myRef.current
      }
      elem.style.backgroundImage = `url(${picture})`
      elem.innerHTML = `
        <div class=${upPicture || picture ? "has-picture" : ""}>
          <img src="${cameraLogo}" alt="Camera" />
          <div>${upPicture ? upPicture.name : ""}</div>
        </div>
      `
      showPicture()
    }
  }

  const onDropPicture = pic => {
    setUpPicture(pic[0])
  }

  useEffect(() => {
    updateChooseFileLogo()
  }, [picture])

  useEffect(() => {
    updateChooseFileLogo()
  }, [upPicture])

  return (
    <Styles className="taman-image-upload" ref={myRef}>
      {label ? <label htmlFor={inputId}>{label}</label> : <></>}
      <ImageUploader
        id={inputId}
        singleImage={true}
        withIcon={true}
        buttonText=""
        onChange={onDropPicture}
        imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
        maxFileSize={2097152}
        {...otherProps}
      />
      <div
        style={{
          height: "0",
          textAlign: "center",
          width: "270px",
          display: "inline-block",
        }}
      >
        <small>Max file size: 2mb</small>
      </div>
    </Styles>
  )
}
