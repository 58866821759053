import styled from "styled-components"

export const Styles = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 768px;

  .profil-photo-text {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .profil-photo-text > div:last-child {
    width: calc(100% - 270px);
    padding-left: 20px;
    padding-right: 20px;
  }

  .input-wrapper {
    padding-bottom: 10px;
  }

  button {
    width: 100%;
  }

  @media only screen and (max-width: 767.98px) {
    margin-left: -17px;
    margin-right: -17px;

    .profil-photo-text {
      flex-wrap: wrap;
      justify-content: center;
    }

    .profil-photo-text > div:last-child {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
`
